import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-ios-cloud-download',
        title:'Custom storage',
        text:'Choose the storage size that best meets your needs and decide to share some of it with the rest of the network.'
    },
    {
        _id:'002',
        classIcon:'ion-android-archive',
        title:'Digital Asset Manager',
        text:'Trust the security, versatility, scalability and efficiency of our decentralized repository. Our internal solution that replaces IPFS.'
    },
    {
        _id:'003',
        classIcon:'ion-ios-folder',
        title:'Assets collection',
        text:'Say no to digital disorganization. Say yes to efficiency and simplicity by grouping digital assets and transferring them atomically.'
    },
    {
        _id:'004',
        classIcon:'ion-android-sync',
        title:'Deliver',
        text:'Decide to sell or deliver a digital asset, such as NFTs, or an asset collection to third parties securely and trustfully.'
    },
    {
        _id:'005',
        classIcon:'ion-ribbon-a',
        title:'User reward',
        text:'Accumulate tokens to spend within the system based on the amount of storage you will share into the Neatbox network.'
    },
    {
        _id:'006',
        classIcon:'ion-network',
        title:'Ownership transfer',
        text:'The ownership and copyright of the asset is guaranteed by the blockchain and the information is public: you can see a history of transactions and owners.'
    }
]
function Service() {
    return (
        <>
        <section  id="service" className="light">
            <div className="bg-light"></div>
                

                <div className="block">
                    <div className="container">
                            <div className="section-sub-title center">
                                <article className="section-title-body">
                                <h1 className="head-title">Our <span>Services</span></h1>
                                       
                                </article>
                            </div>
                        <div className="row">
                            {services.map((service)=>(
                            <div className="col-lg-4 col-md-6"  key={service._id}>
                                <div className="block-feature-icon  center dark">
                                    <div className="icon hi-icon-color hi-icon-effect">
                                        <span className="hi-icon"><i className={service.classIcon}></i></span>
                                    </div>
                                    <div className="block-title">
                                        <h4>{service.title}</h4> 
                                        <p>{service.text}</p>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
        </section>
        </>
    )
}


export default Service
