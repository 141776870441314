import React from 'react'

function Map() {
    return (
        <>
      
         <div className="google-map">

         <iframe title="My Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50585214.276544444!2d15.595136447842672!3d39.300122171522425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133f90caee6d43f5%3A0x27d5b05c2b401d8!2sVia%20Francesco%20Capoderose%2C%204%2C%2087100%20Cosenza%20CS!5e0!3m2!1sit!2sit!4v1672243059383!5m2!1sit!2sit"  loading="lazy"></iframe>
       
         </div>

          </>
        
    )
}

export default  Map
