import React from 'react';
import Header1 from './../components/Header/Header1';
import Welcome from './../components/Welcome';

import AboutUs from './../components/AboutUs';
import Service from './../components/Service';
import Roadmap from '../components/Roadmap';
import Team from './../components/Team';
import Social from './../components/Social';
import Blog from './../components/Blogs/Blog';
import Guide from './../components/Guide';
import ContactUs from './../components/Contact/ContactUs';
import Map from './../components/Map';
import Footer from './../components/Footer';
import SimpleSlider from './../components/Sliders/Slick-slider1'

function Home1() {
  return (
    <>
      
      <div  id="home" className="full-screen-block">
      <Header1/>
      <SimpleSlider/>
      </div>
      <Welcome/>

      <AboutUs/>
      <Service/>
      <Roadmap/>
      <Team/>
      <Social/>
      <Blog/>
      <Guide/>
      <ContactUs/>
      <Map/>
      <Footer/>
    </>
  );
}

export default Home1;