import React from 'react';


function Roadmap() {
    
    return (
        <>
            <section id="roadmap" className="light">
            <div className="bg-light"></div>
            <div className="block">
                <div className="container">
                    <div className="row align-items-center  gx-5">
                        <div className="col-lg-6">
                            <div className="section-sub-title">
                                <article className="section-title-body">
                                    <h1 className="head-title"><span>Road</span>Map - Lisk Grant Program</h1>
                                    <ul className="list-unstyled list-bold d-lg-none d-xl-block">
                                   <details><summary>
                                        <li><i className="ion-unlocked"></i>MILESTONE 1: PROJECT SETUP</li></summary>
                                        
                                        <p>
                                        - Sign the agreement.<br></br>
                                        - Verify team’s identity with Lisk.<br></br>
                                        - Setup the project (name, logo, website, GitHub repository, Twitter proﬁle, Discord channel).<br></br>
                                        - Publish a blog post covering the team and project on the Recipient’s own website.   </p>
                                        </details> 
                                        <details><summary>
                                        <li><i className="ion-locked"></i> MILESTONE 2: MVP</li></summary>
                                       
                                        <p>
                                        - Develop an MVP with the latest Lisk SDK covering the essential features of the Recipient’s blockchain application.<br></br>
                                        - Publish a blog post covering the MVP on their own website.<br></br>
                                        - Conduct a business review by Lisk to receive feedback about the MVP.    </p>
                                        </details> 
                                        <details> <summary>
                                        <li><i className="ion-locked"></i> MILESTONE 3: DEVELOPMENT</li></summary>
                                        
                                        <p>
                                        - Propose three goals, for which the Recipient shall receive in-between ﬁnancial support.<br></br>
                                        - Publish a blog post covering each goal’s completion.<br></br>
                                        - Pass a high-level developer review by Lisk and ﬁx all remarks.<br></br>
                                        - Launch a public testnet for the blockchain application. Publish a blog post covering the testnet launch.<br></br>
                                        - Keep the testnet running for one month to receive the payout.    </p>
                                        </details> 
                                        <details> <summary>
                                        <li><i className="ion-locked"></i> MILESTONE 4: LAUNCH</li></summary>
                                        
                                        <p>
                                        - Launch the mainnet for the blockchain application.<br></br>
                                        - Publish a blog post covering    </p>
                                        </details> 
                                    </ul>
                                </article>
                            </div>                      
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-block">
                            <div className="img-rotate wow fadeInRightBig animated" data-wow-duration="1.5s">
                                <img alt="" className="img-fluid" src="img/bg/bg11.png"/>

                                
                                </div>    
                            </div>
                            
                        </div>
                    </div>
                
                    <div className="row gx-5 align-items-center">
                        <div className="col-md-6 col-sm-12">
                            <div className="relative">
                                <div className="h100  d-md-none"></div>
                                <div className="img-rotate wow fadeInLeftBig animated" data-wow-duration="1.5s">
                                    <img className="img-fluid" src="img/preview/winner's cup.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="block pad-l-30">
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 align="justify">
                                <font color="000000"> The Lisk Foundation's Grant Program is offering milestone-based funding to support apps built with the Lisk SDK and aimed at expanding the Lisk Ecosystem.</font>
                                </h4>
                                <br></br>
                                <a href="https://lisk.com" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>Lisk Grant</a>



                            </div>
                       
                </div>
            </div>
                </div>
                </div>
            </div>
        </section>
        <section id="about" className="light">
           
        </section>
        </>
    )
}

export default Roadmap
