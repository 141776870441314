import React from 'react'
function AboutUs() {
    
    return (
        <>
             <section  id="about">
            <div className="bg-dark"></div>    
            <div className="section-block-title">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9">
                                <article className="section-sub-title center">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s"><font color="ffffff">A little <font color="EAC445">about us</font></font></h1>
                                    </article>
                            </div>  
                        </div>
                     </div>
                
            
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-2 d-flex align-items-center">
                                <div className="widget-block relative">
                                <div className="h100 d-none d-lg-block"></div>
                                <h4 className="widget-title">Our company</h4>
                                <p>
                                The idea of creating a distributed cloud was born, after a careful analysis of the actual market needs, from a collaboration between the Blockchainlab S.r.l. and Blockchain Governance Technologies S.r.l.<br></br>
                                Blockchainlab was founded in 2015 with the aim of aggregating scientific excellence and professional excellence in the field of innovation and technological research that aims to use the blockchain and the Internet of Things. Since 2017, BlockchainLab Srl has started its R&D headquarters in Cosenza where a strategic partnership has been launched with the DEMACS - DEpartment of MAthematics and Computer Sciences for the development of the IoT & B project funded under the POR Calabria 2014 - 2020.
                                </p>
                                <a href="https://www.blockchainlab.it/" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>BlockchainLab</a>
                                <p>
                                Blockchain Governance, an innovative startup born in 2016, offers targeted consulting products and services to meet the needs of a large and diverse clientele in a professional, effective and innovative way. The company values the experience acquired in research activities relating to complementary and alternative currencies carried out at the Faculty
                                of Economics and in the Department of Mathematics and Computer Science of the University of Calabria - UNICAL.


                                </p>
                                <a href="https://www.blockchain.srl/" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>Blockchain Governance</a>
                                <h4 className="widget-title">The spin-off</h4>  
                                <p>
                                Thanks to the collaboration  with specialized personel in these areas of expertise,  to the strong ties with international partners as well as those with the university itself, Blockchain Governance and Blockchainlab have given birth to a spin-off - BEEZ SRL. A cutting-edge startup offering highly innovative and customized solutions.
                                </p>

                                <a href="https://www.beez.network" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>BEEZ</a>

                                   {/*<div className="block-feature d-lg-none d-xxl-block">
                                        <i className="ion-image"></i>
                                        <a href="/">Photo Design</a>
                                        <p>
                                        Duis aute irure dolor quis dolor quis nostrud exercitation ullamco laboris nisi ut in. Eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                     </div>*/}
                              
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 d-flex align-items-end">
                                <div  className="relative  d-flex">
                                        <div className="card-block  lax lax_preset_fadeIn:50:150  lax_preset_slideY:4500:700">
                                            <div className="card-info">
                                                <h2>100%</h2>
                                                <h4>BLOCKCHAIN<br></br>TECHNOLOGY</h4>
                                            </div>
                                           
                                        </div>
                                        <img className="img-fluid" src="img/bg/bg4_aboutus copy.png" alt=""/>
                                </div>
                            
                            </div>
   
                        </div>
                    </div>
                
            </div>
           

       
           <div className="col-lg-6 order-lg-1 d-flex align-items-end">
                                <div  className="relative  d-flex">
                                        <div className="card-block2  lax lax_preset_fadeIn:50:150  lax_preset_slideY:4500:700">
                                            <div className="card-info">
                                                <h2>100%</h2>
                                                <h4>INNOVATION<br></br> LAB</h4>
                                            </div>
                                           
                                        </div>
                                </div>
                            
                            </div>
                            <div className="col-lg-6 order-lg-1 d-flex align-items-end">
                                <div  className="relative  d-flex">
                                        <div className="card-block3  lax lax_preset_fadeIn:50:150  lax_preset_slideY:4500:700">
                                            <div className="card-info">
                                                <h2>100%</h2>
                                                <h4>RESEARCH <br></br>& <br></br>SHARING</h4>
                                            </div>
                                           
                                        </div>
                                </div>
                            
                            </div>
        </section>
        </>
    )
}

export default AboutUs
