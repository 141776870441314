import React from 'react';


function Footer() {
    return (
        <>
        <footer id="footer">
            <div className="container">
                <div className="row">
                
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <article className="footer-info-block">
                            <div className="section-sub-title">
                            {/* <article className="section-title-body white">
                                    <h4 className="head-title">INFORMATION</h4>
                                </article>*/}
                            </div>
                            <p> <a href="https://www.neatbox.it"> Neatbox</a>. A cloud storage that relies on a distributed storage system and on blockchain technologies - <a href="https://lisk.com"> Lisk</a>.</p>
                           
                        </article>
                    </div>        
                </div>
                <div className="footer-bottom">
                    <div className="row_logo">

                    
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="footer-logo">
                                <img src="img/Neatbox_black.png" alt=""/>             
                            </div>
                        </div>
                        <p>Copyright ©2022 Neatbox - Blockchain Governance SRL - Developed by MELANIA.MARTELLI. All Rights Reserved.</p>
                    </div>

                </div>

            </div>

        </footer>
        </>
    )
}

export default Footer
