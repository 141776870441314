import React from 'react'
import {FacebookShareButton,
    PinterestShareButton,
    LinkedinShareButton,
    TwitterShareButton} from "react-share";
import {FacebookIcon,
    PinterestIcon,
    LinkedinIcon,
    TwitterIcon} from "react-share";
function Team() {
  
    return (
        <>
        <section id='team'>
            <div className="block color-scheme-2">
                <div className="container">
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s"><font color="ffffff">Our <font color="EAC445">teams</font></font></h1>
                        </article>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team1.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">CEO & Director</p>
                                        <h4>Stella d'Atri</h4>
                                        <p className='giustificato'> Corporate governance specialist with a broad experience in shareholders minorities representation and general meetings proxy.</p>

                                    </div>

                                </figure>
                                <div className="left-icon">
                                    <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.linkedin.com/in/stelladatri/" 
                                            quote= "Neatbox team" hashtag="#neatboxTeam">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                       
                                        <li>
                                            <LinkedinShareButton url="https://www.linkedin.com/in/stelladatri/" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://www.linkedin.com/in/stelladatri/">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team2.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Research Scientist</p>
                                        <h4>Enrico Zanardo</h4>
                                        <p className='giustificato'>Researcher PHD Candidate in Blockchain Technologies at University of Nicosia. Lecturer at MCAST Malta.</p>
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.linkedin.com/in/enrico-zanardo-a81607156/" 
                                            quote= "Neatbox team" hashtag="#neatboxTeam">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        
                                        <li>
                                            <LinkedinShareButton url="https://www.linkedin.com/in/enrico-zanardo-a81607156/" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://www.linkedin.com/in/enrico-zanardo-a81607156/">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team3.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Project Architect</p>
                                        <h4>Gianfranco d'Atri</h4>
                                        <p className='giustificato'>Financial Economist and Blockchain professor at the University of Calabria.</p>
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.linkedin.com/in/d-atri-gianfranco/" 
                                            qquote= "Neatbox team" hashtag="#neatboxTeam">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        
                                        <li>
                                            <LinkedinShareButton url="https://www.linkedin.com/in/d-atri-gianfranco/" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://www.linkedin.com/in/d-atri-gianfranco/">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                       
                    </div>

                    <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team5.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Gamification Specialist</p>
                                        <h4>Marianna d'Atri</h4>
                                        
                                        <p className='giustificato'> Gamification and Behavioral Design who works with organizations and individuals who all want to achieve a common goal.</p>
                                        
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.linkedin.com/in/marianna-d-atri-62628416/" 
                                            quote= "Neatbox team" hashtag="#neatboxTeam">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                       
                                        <li>
                                            <LinkedinShareButton url="https://www.linkedin.com/in/marianna-d-atri-62628416/" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                        <PinterestShareButton url="https://www.linkedin.com/in/marianna-d-atri-62628416/" media="https://www.linkedin.com/in/marianna-d-atri-62628416/">
                                            <PinterestIcon round={false} size={36}></PinterestIcon>
                                        </PinterestShareButton>
                                        </li>
                                    </ul>   
                                </div>
                            </article> 
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team8.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Project Manager & Web Designer</p>
                                        <h4>Melania Martelli</h4>
                                        <p className='giustificato'>Project Management Professional (PMP) with extensive experience in Business Operations and efficiencies.Frontend Developer e Product Specialist </p>
                                        
                                    </div>
                                </figure>
                                <div className="left-icon">
                                    <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.linkedin.com/in/melaniamartelli/" 
                                            quote= "Neatbox team" hashtag="#neatboxTeam">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        
                                        <li>
                                            <LinkedinShareButton url="https://www.linkedin.com/in/melaniamartelli/" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://www.linkedin.com/in/melaniamartelli/">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                     
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team9.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">CEO Blockchainlab Srl</p>
                                        <h4>Gerardino Garrì</h4>
                                        <p className='giustificato'>Certified public accountant and auditor.
Expert in business management and in solving problems related to the governance of business processes in all its aspects.
</p>
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.linkedin.com/in/gerardino-garri-87501651/" 
                                            quote= "Neatbox team" hashtag="#neatboxTeam">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        
                                        <li>
                                            <LinkedinShareButton url="https://www.linkedin.com/in/gerardino-garri-87501651/" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://www.linkedin.com/in/gerardino-garri-87501651/">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                    </div>

                </div>
            </div>          
        </section>
        </>
    )
}

export default Team
