import React from 'react'
function Guide() {
    
    return (
        <>
             <section  id="guide">
            <div className="bg-dark"></div>    
            <div className="section-block-title">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9">
                                <article className="section-sub-title center">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s"><font color="ffffff">Neatbox <font color="EAC445"> User's Guide</font></font></h1>
                                    </article>
                            </div>  
                        </div>
                     </div>
                
            
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-2 d-flex align-items-center">
                                <div className="widget-block relative">
                                <div className="h100 d-none d-lg-block"></div>
                               
                                <p>
                                Access your documents from anywhere, at any time, on any device.<br></br><br></br>
                                If you are intrigued by the features and services that Neatbox offers, we recommend following our tutorial that will show you, step by step, 
                                everything you need to know in order to fully understand all its features and become a new user of the network. 
                                
                                <br></br>
                                <br></br>
                                Got a minute? Join us. Get started!                               
                                </p>
                                <a href="https://guide.neatbox.it/home-english.html" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>User's Guide</a>
                                



                                 
                              
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 d-flex align-items-end">
                            <img className="img-fluid" src="img/preview/guide_neatbox.png" alt=""/>

                            
                            </div>
   
                        </div>
                    </div>
                
            </div>
           

       
          
        </section>
        </>
    )
}

export default Guide
