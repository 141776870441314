import React from 'react'
import SliderClients from './Sliders/Slick-clients'
function Clients() {
  
    return (
        <>
        <section  id="social" className="light">
        <div className="bg-light"></div>
            <div className="bg-light"></div>
                

                <div className="block">
                    <div className="container">
                    <div className="row justify-content-center">
                                <div className="pad-l-45 pad-r-45"></div>
                        <div className="col-md-10 col-lg-9">
                           
                                <p className="head-text"><font color="000000">Follow us! We will always keep you informed about updates and development of our project because sharing is important to us.</font></p>
                                <div id="owl-partners"  className="owl-carousel">
                        <SliderClients/>
                    </div>
       </div>
       </div> </div></div>
        </section>
        </>
    )
}

export default Clients
