

import React from "react";
import ReactDOM from "react-dom";


import Template from './pages/Home1';


const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
        <Template/>
  </React.StrictMode>,
  rootElement
);

