import React from 'react'
import ContactForm from './../Contact/ContactForm'
import {FacebookShareButton,
    TelegramShareButton,
    LinkedinShareButton,
    TwitterShareButton} from "react-share";
import {FacebookIcon,
    TelegramIcon,
    LinkedinIcon,
    TwitterIcon} from "react-share";
function ContactUs() {
    return (
        <>
        <section  id="contact" className="light">
          <div className="bg-light"></div>
                <div className="block">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-6">
                                <div className="section-sub-title">
                                    <article className="section-title-body">
                                        <h1 className="head-title">Let's meet</h1>
                                    </article>
                                </div>
                                <div className="widget-block dark">
                                    <h4 className="widget-title"><i className="ion-pound"></i>Neatbox Office</h4>
                                    <h4 className="widget-title"><i className="ion-map"></i>via F. Capoderose n.4, Cosenza (Italy) IT-87100</h4>
                                    <br/>
                                    <FacebookShareButton url="https://www.neatbox.it" 
                                    quote= "test" hashtag="#neatbox">
                                        <FacebookIcon round={true} size={32}></FacebookIcon>
                                    </FacebookShareButton>
                                    &nbsp;
                                    <LinkedinShareButton url="https://www.neatbox.it">
                                        <LinkedinIcon round={true} size={32}></LinkedinIcon>
                                    </LinkedinShareButton>   
                                    &nbsp; 
                                    <TwitterShareButton url="https://www.neatbox.it">
                                        <TwitterIcon round={true} size={32}></TwitterIcon>
                                    </TwitterShareButton> 
                                    &nbsp;   
                                    <TelegramShareButton url="https://www.neatbox.it">
                                        <TelegramIcon round={true} size={32}></TelegramIcon>
                                    </TelegramShareButton>    
                                </div>
                                <div className="row">
                                   
                                    <div className="col-lg-6">
                                        <div className="widget-block dark">
                                            <h4 className="widget-title"><i className="ion-email"></i>Email:</h4>
                                            <p> info@neatbox.it<br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 align-items-center">
                                <div className="form-light">
                                    <ContactForm/>
                                </div>
                                
                            </div>                                  
                        </div>
                    </div>
                </div>
            
        </section>
        </>
    )
}

export default ContactUs
