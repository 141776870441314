import React from 'react'
import BlogItem from './BlogItem'

const blogs = [
    {
        _id:'999',
        title:'Ready, Set, Launch!',
        text:' We are thrilled to announce the success of our latest milestone with the launch of the Neatbox testnet! This launch provides developers with an opportunity to test and debug Neatbox and blockchain protocols firsthand. The primary objective of this program is to foster innovation within the community-focused Lisk ecosystem. With the highly scalable and... ',
        url:'https://blog.neatbox.it/?p=2350',
        src:'img/preview/TESTNETLAUNCH.jpg',
        day:'27',
        month:'Mar',
        year: '2023',
        author:'the_blogger'
    },
    {
        _id:'000',
        title:'Meeting our Goals!',
        text:'As we approach the launch of our testnet, it’s essential to reflect on the objectives we have set for ourselves and their significance in achieving improved results. Let’s delve into each objective and their implications. Objective 1: DAM – Digital Asset Management...',
        url:'https://blog.neatbox.it/?p=2340',
        src:'img/preview/ourgoals_neatbox.png',
        day:'20',
        month:'Mar',
        year: '2023',
        author:'the_blogger'
    },
    {
        _id:'001',
        title:'OUR FILE TRANSFER SERVICE.',
        text:'Neatbox is a Lisk-based cloud storage, but the product will have an additional feature that we are implementing right now. It will allow users to not have to look elsewhere in order to share files online. In fact, one often needs to send files, even and especially large files, to the office or home and it is not always possible to attach them in an email. Sometimes you just need to do some resizing and/or compression into .ZIP files or take advantage of the services offered by email clients. Yet it involves necessarily having to take additional steps that turn a simple sharing into a hostile and not immediate procedure. By now ...',
        url:'https://blog.neatbox.it/?p=277',
        src:'img/preview/deliver.png',
        day:'09',
        month:'Nov',
        year: '2022',
        author:'the_blogger'
    }
   
    
]

function Blog() {
    return (
        <>
        <section  id="blog" className="single-bg">
            <div className="section-block-title">
                <div className="bg-dark"></div>

                    <div className="container">
                        <div className="section-sub-title center">
                            <article className="section-title-body white">
                                <h1 className="head-title">Last <span>News</span> from Neatbox</h1>
                            </article>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {
                                blogs.map((blog)=>(
                                    <div className="col-md-6 col-xl-4" key={blog._id}>
                                        <BlogItem 
                                        title={blog.title} 
                                        text={blog.text}
                                        url={blog.url}
                                        src={blog.src}
                                        day={blog.day}
                                        month={blog.month}
                                        year={blog.year}
                                        author={blog.author} />
                                    </div>
                                ))}
                            
                        </div>
                    </div>
                <div className="h50"></div>
                <div className="container">
                        <div className="section-sub-title center">
                <a href="https://blog.neatbox.it/" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>Visit our Blog</a>
                </div>      </div>   
            </div>      
 
        </section>
        </>
    )
}

export default Blog
