import React, { Component } from "react";
import Slider from "react-slick";

export default class SliderClients extends Component {
  render() {
    const settings = {
      dots: false,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 400000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
      ]
    };
    return (
      <>
     
        <Slider {...settings}>
                            <div className="text-center">
                            
                            <a href="https://discord.gg/EaE79ZpZ" target="_blank" rel="noopener noreferrer"><img src="img/discord.png" alt="" className="img-fluid"/></a>
                            </div>
                            <div className="text-center">
                            <img src="img/null.png" alt="" className="img-fluid"/>
                            </div>
                            <div className="text-center">
                            <a href="https://twitter.com/NeatboxLAB" target="_blank" rel="noopener noreferrer"> <img src="img/twitter.png" alt="" className="img-fluid"/></a>
                                
                            </div>
                            
        </Slider>
        
      </>
    );
  }
}