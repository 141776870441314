import React from 'react'
import {Link} from 'react-scroll'

function Welcome() {



    return (
           
           
           
        <section id='welcome'>
            
        <div className="block color-scheme-1">
                
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-sub-title center">
                                <article className="section-title-body white">
                                    <h1 className="head-title">
                                    
                                   <span><font color="000000">A</font> different </span><font color="000000">way of </font><span>cloud storage</span> 
                                 </h1>
                                    <a href="https://app.stellab.it" target="_blank" rel="noopener noreferrer" class="btn2-default active btn-move t1"smooth={false} duration={800}>Try it</a>
                                    <br></br>
                                    <a href="https://guide.neatbox.it/home-english.html" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>How to</a>
                                    <p className="head-text"><font color="262626">
                                    Neatbox is a cloud storage that relies on a decentralized storage system called Digital Asset Manager with regard to file uploading, and Lisk blockchain technology to ensure ownership rights and immutability of data.
                                    <br></br>Neatbox is owned by Beez SRL. Learn more about us.
                                    <br></br>
                                    </font>
                                    </p>
                                    <a href="https://www.beez.network" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>The Company</a>

                                    <p className="head-text"><font color="262626">
                                    The user can purchase a physical hardware device to use the storage memory exclusively or share it with a part of the network.
                                    For those, on the other hand, who decide to use only the cloud storage service, without purchasing the physical device, it will also be possible to use NeatBox, by using the cloud memory shared by other users and paying a fee that will serve to keep the network operational and to reward users who have shared their memory.
                                     </font> 
                                     </p>
                                     <Link to='service' className="btn-default active btn-move" smooth={false} duration={800}>SERVICES</Link>&nbsp;
                                    <a href="https://lisk.com" target="_blank" rel="noopener noreferrer" class="btn-default active btn-move t1"smooth={false} duration={800}>Lisk</a>
                                </article>
                               
                                 
                               
                            </div>   
                            
                        </div>
                       
                        </div>
                    
                </div>
                

            </div>
           
            
    </section>
       




       

    )    

}

export default Welcome
